.result {
  @include size(100%, 100%);
  @include flexbox(column, center, space-around);
  background-color: $white_alpha;
  position: relative;
  .summary {
    @include size(90%, 68%);
    @include flexbox(column, center, space-around);
    background-color: $white;
    border: 2px solid $black;
    .summary_inner {
      @include size(95%, 58%);
      @include flexbox(column, center, space-around);
      .header {
        @include size(100%, 16%);
        @include flexbox(row, center, space-between);
        h3 {
          font: bold 20px $font_josefin_sans;
          margin: 0;
        }
        .score {
          font: bold 14px $font_noto_sans;
        }
      }
      .table_wrapper {
        @include size(100%, 70%);
        border: 1px solid $light_gray;
        .react-bootstrap-table {
          @include size(100%, 100%);
          background-color: $white;
          display: block;
          overflow-y: scroll;
          table-layout: fixed;
          .table {
            border: 0;
            color: $black;
            font-size: 12px;
            text-align: center;
            width: 100%;
            tr {
              display: block;
              width: 100%;
            }
            th,
            td {
              border: 0;
              display: inline-block;
              padding: 2px;
              width: 25%;
            }
            thead,
            tbody {
              border: 0;
              display: block;
              width: 100%;
            }
            thead {
              background-color: $light_yellow;
              border-bottom: 1px solid $black;
              position: sticky;
              position: -webkit-sticky;
              top: 0;
              z-index: 1;
              th {
                border: 0;
              }
            }
            tbody {
              tr {
                border-bottom: 1px solid $light_gray;
              }
            }
          }
        }
      }
      .btn_group {
        @include size(100%, 10%);
        @include flexbox(row, center, space-between);
        .btn_group_back {
          @include size(60%, 100%);
          @include flexbox(row, center, space-between);
          .btn_ {
            @include size(48%, 100%);
            @include flexbox(column, center, center);
            font: bold 12px $font_basic;
            background-color: $pink;
          }
        }
        .btn_end {
          @include size(38%, 100%);
          @include flexbox(column, center, center);
          font: bold 12px $font_basic;
          background-color: $red;
        }
        .btn_,
        .btn_:hover,
        .btn_:focus,
        .btn_.focus,
        .btn_:not(:disabled):not(.disabled):active,
        .btn_:not(:disabled):not(.disabled).active,
        .btn_:not(:disabled):not(.disabled):active:focus,
        .btn_:not(:disabled):not(.disabled).active:focus {
          border: solid $black 2px;
          border-radius: 20px;
          color: $white;
          padding: 0;
          &:hover {
            background-color: $pink_alpha;
          }
        }
      }
    }
    .review {
      @include size(95%, 40%);
      font: bold 12px $font_basic;
      overflow-y: scroll;
      padding: 10px 5px;
      .review_inner {
        div {
          line-height: 1.4em;
          margin-bottom: .5em;
          .num {
            font-size: 14px;
          }
        }
        .choices {
          display: inline;
          &:not(:first-of-type) {
            margin-left: 1.2em;
          }
        }
      }
    }
  }
}
.logout_msg {
  color: $dark_gray;
  font: 16px $font_basic;
}

@media screen and (min-width: $break_point_s) {
  .result {
    .summary {
      .summary_inner {
        .btn_group {
          .btn_retry {
            span:after {
              content: '解く';
            }
          }
          .btn_select {
            span:before {
              content: '問題';
            }
          }
          .btn_end {
            @include size(32%, 100%);
            span:after {
              content: '(閉じる)';
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: $break_point) {
  .result {
    background-color: transparent;
    display: block;
    position: relative;
    .meta {
      @include position_absolute(15px, 15px, auto, auto);
      z-index: 999;
    }
    .summary {
      @include size(100%, 100%);
      @include position_absolute(0, 0, 0, 0);
      background-color: transparent;
      border: none;
      display: block;
      .summary_inner {
        @include size(48%, 100%);
        @include position_absolute(0, 0, auto, auto);
        @include flexbox(column, center, space-between);
        .header {
          @include size(calc(100% - 30px), 25%);
          @include flexbox(row, flex-start, space-between);
          h3 {
            font-size: 30px;
            margin-top: 15px;
          }
          .score {
            @include size(200px, 30%);
            @include flexbox(column, center, center);
            align-self: flex-end;
            background-color: $white;
            border: 2px solid $black;
            font-size: 16px;
          }
        }
        .table_wrapper {
          @include size(calc(100% - 30px), 55%);
        }
        .btn_group {
          @include size(calc(100% - 30px), 10%);
          margin-bottom: 15px;
          .btn_group_back {
            .btn_ {
              font: bold 14px $font_basic;
            }
          }
          .btn_end {
            @include size(28%, 100%);
            font: bold 14px $font_basic;
            span:after {
              content: '\A(画面を閉じる)';
              white-space: pre;
            }
          }
          .btn_ {
            font: bold 14px $font_basic;
          }
        }
      }
      .review {
        @include size(48%, 42%);
        @include position_absolute(auto, auto, 20px, 0);
        background-color: $white;
        font-size: 16px;
        padding: 20px;
        .review_inner {
          div {
            .num {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .logout_msg {
    font: 20px $font_basic;
  }
}
