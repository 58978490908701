.login {
  @include size(300px, 360px);
  @include position_absolute(0,0,0,0);
  @include flexbox(column, center, space-between);
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  .logo {
    @include size(100%, 30%);
    background: $logo center/contain no-repeat;
    h1 {
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
    }
  }
  .form_wrapper {
    @include size(100%, 68%);
    @include flexbox(column, center, center);
    background-color: rgba(255,255,255,0.54);
    .status{
      @include size(100%, 8%);
      @include flexbox(row, center, center);
      font: 16px $font_josefin_sans;
      .spinner-grow {
        @include size(1.5em, 1.5em);
        margin-right: .3em;
        margin-bottom: .3em;
      }
      .loading {
        color: $dark_gray;
      }
    }
    form {
      @include size(100%, 80%);
      @include flexbox(column, center, space-around);
      .input,
      .btn_login {
        @include size(80%, 22%);
        font: bold 16px $font_basic;
        text-align: center;
      }
      .input,
      .input:focus,
      .input:active,
      .input.active {
        border: $black solid 2px;
        border-radius: 0;
        color: $black;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
        font-family: $font_josefin_sans;
        &::placeholder {
          color: $dark_gray;
        }
      }
      .btn_login,
      .btn_login:hover,
      .btn_login:focus,
      .btn_login.focus {
        background-color: $dull_blue;
        border: none;
        border-radius: 10px;
        color: $white;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
      }
      .btn_login:not(:disabled):not(.disabled):active,
      .btn_login:not(:disabled):not(.disabled).active,
      .btn_login:not(:disabled):not(.disabled):active:focus,
      .btn_login:not(:disabled):not(.disabled).active:focus {
        background-color: $dull_blue_alpha;
      }
    }
  }
}

@media screen and (min-width: $break_point) {
  .login {
    @include size(500px, 600px);
    .form_wrapper {
      .status {
        font-size: 24px;
      }
      form {
        .input,
        .btn_login {
          font-size: 30px;
        }
        .input,
        .input:focus,
        .input:active,
        .input.active {
          border: $black solid 4px;
        }
      }
    }
  }
}
