.btn_group {
  .btn_choice {
    @include size(45px, 45px);
    margin: 0 10px;
    padding: 0;
    font: bold 18px $font_basic;
    span {
      @include size(100%, 50%);
      font: bold 16px $font_basic;
    }
    small {
      @include size(100%, 25%);
      font: bold .6em $font_basic;
    }
  }
  .btn_choice,
  .btn_choice:hover,
  .btn_choice:not(:disabled):not(.disabled):active,
  .btn_choice:not(:disabled):not(.disabled).active,
  .btn_choice:not(:disabled):not(.disabled):active:focus,
  .btn_choice:not(:disabled):not(.disabled).active:focus{
    color: $black;
    border: none;
    background-color: $blue;
    box-shadow: none;
    border-radius: 5px;
  }
  .selected,
  .selected:hover,
  .selected:not(:disabled):not(.disabled):active,
  .selected:not(:disabled):not(.disabled).active,
  .selected:not(:disabled):not(.disabled):active:focus,
  .selected:not(:disabled):not(.disabled).active:focus{
    background-color: $light_pink;
  }
}
.preview {
  @include flexbox(column, center, space-between);
  .meta {
    @include size(100%, 17%);
    .test {
      display: none;
    }
    .info {
      @include size(100%, 100%);
      @include flexbox(column, center, center);
      border: 3px solid $light_gray;
      color: $dark_gray;
      font: bold 14px $font_basic;
      text-align: center;
    }
  }
  .question_set {
    @include size(100%, 60%);
    font-size: 12px;
    padding: 3px;
    .instruction,
    .question_ {
      margin-bottom: .5em;
      line-height: 1.4em;
    }
    .choices {
      line-height: 1.4em;
      div {
        display: inline-block;
      }
    }
  }
  .btn_start {
    @include size(100%, 20%);
    @include flexbox(column, center, center);
    font: bold 14px $font_basic;
  }
  .btn_start,
  .btn_start:hover,
  .btn_start:focus,
  .btn_start.focus,
  .btn_start:not(:disabled):not(.disabled):active,
  .btn_start:not(:disabled):not(.disabled).active,
  .btn_start:not(:disabled):not(.disabled):active:focus,
  .btn_start:not(:disabled):not(.disabled).active:focus {
    background-color: $light_pink;
    border: solid $black 2px;
    border-radius: 5px;
    color: $black;
    &:hover {
      background-color: $light_pink_alpha;
    }
  }
}
.guidance {
  @include flexbox(row, center, space-between);
  h2 {
    @include size(30%, auto);
    color: $pink;
    font: bold 20px $font_josefin_sans;
    margin: 0;
  }
  span {
    @include size(68%, auto);
    font: bold 14px $font_basic;
  }
}

.modal_select_test {
  max-height: 90%;
  .modal-header {
    .guidance {
      @include size(100%, auto)
    }
  }
  .modal-content {
    .modal-body {
      .btn_group_testset {
        max-height: 300px;
        overflow-y: scroll;
        margin-bottom: 10px;
      }
      .btn_group_testset {
        @include flexbox(row, flex-start, flex-start);
        flex-wrap: wrap;
        padding-bottom: 7px;
        .btn_choice {
          @include flexbox(row, center, center);
          margin: 7px;
          small {
            padding-top: .3em;
          }
        }
      }
      .preview {
        @include size(100%, 150px);
      }
    }
  }
}

.training {
  @include size(100%, 100%);
  @include flexbox(column, center, space-around);
  background-color: $white_alpha;
  .selection {
    @include size(90%, 68%);
    @include flexbox(column, center, space-around);
    background-color: $white;
    border: 2px solid $black;
    .steps {
      @include size(100%, 100%);
      .tag,
      .level,
      .testset {
        .guidance {
          @include size(90%, 30%);
        }
      }
      .tag {
        @include size(100%, 50%);
        @include flexbox(column, center, space-between);
      }
      .level {
        @include size(100%, 40%);
        @include flexbox(column, center, center);
      }
      .tag,
      .level {
        .btn_group {
          @include size(90%, 55%);
        }
        .dropdown_area {
          @include size(90%, 33%);
          @include flexbox(column, center, center);
          border: 1px solid $light_gray;
          &>div {
            @include size(100%, auto);
            .menu {
              @include size(100%, auto);
              text-align: center;
            }
            .toggle,
            .item {
              @include size(100%, auto);
              font: bold 12px $font_basic;
              border: none;
              border-radius: 0;
              padding: 2px 0;
            }
          }
        }
        .btn_group_level {
          @include flexbox(row, center, center);
          .btn_choice {
            @include flexbox(column, center, center);
          }
        }
      }
      .testset {
        @include size(100%, 78%);
        @include flexbox(column, center, flex-start);
        overflow-y: scroll;
        .btn_group_testset {
          @include size(95%, 70%);
          @include flexbox(row, flex-start, center);
          flex-wrap: wrap;
          overflow-y: scroll;
          padding-bottom: 7px;
          .btn_choice {
            @include flexbox(row, center, center);
            margin: 7px;
            small {
              padding-top: .3em;
            }
          }
        }
      }
      .return_step {
        @include size(100%, 20%);
        @include flexbox(row, center, space-between);
        .selected {
          @include size(60%, 100%);
          @include flexbox(row, flex-start, flex-start);
          &>span {
            @include size(50%, 100%);
            font: bold 14px $font_basic;
          }
        }
        .btn_return {
          @include size(auto, 80%);
          @include flexbox(column, center, center);
          margin-right: 5px;
          span {
            font: bold 12px $font_josefin_sans;
          }
          small {
            font: bold 10px $font_basic;
          }
        }
        .btn_return {
          background-color: $white;
          border: 2px solid $pink;
          border-radius: 10px;
          color: $pink;
          padding: 5px 10px;
        }
        .btn_return:hover,
        .btn_return:focus,
        .btn_return.focus,
        .btn_return:not(:disabled):not(.disabled):active,
        .btn_return:not(:disabled):not(.disabled).active,
        .btn_return:not(:disabled):not(.disabled):active:focus,
        .btn_return:not(:disabled):not(.disabled).active:focus{
          background-color: $white;
        }
      }
    }
  }
}

@media screen and (min-width: $break_point_s) {
  .training{
    .selection {
      .steps {
        .tag,
        .level {
          .dropdown_area {
            &>div {
              .toggle,
              .item {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: $break_point) {
  .preview {
    @include flexbox(column, center, space-around);
    .meta {
      @include size(90%, 20%);
      @include flexbox(row, center, space-between);
      .test {
        display: block;
        @include size(50%, 100%);
        @include flexbox(column, center, space-between);
        .tag,
        .level {
          @include size(100%, 45%);
          @include flexbox(row, center, center);
          font: bold 16px $font_basic;
        }
        .level {
          border: $blue 2px solid;
          background-color: $white;
          color: $blue;
        }
      }
      .info {
        @include size(40%, 100%);
        @include flexbox(column, center, center);
        border: 2px solid $black;
        background-color: $light_yellow;
        color: $black;
        font-size: 20px;
      }
    }
    .question_set {
      @include size(90%, 45%);
      font-size: 14px;
      padding: 10px;
      text-align: center;
    }
    .btn_start {
      @include size(30%, 20%);
      font-size: 20px;
    }
  }
  .modal_select_test {
    max-width: 80%;
    max-height: 90%;
    .modal-header {
      .guidance {
        @include flexbox(row, center, flex-start);
        h2 {
          @include size(15%, auto);
        }
        span {
          @include size(68%, auto);
        }
      }
    }
    .modal-content {
      .modal-body {
        .btn_group_testset {
          max-height: 300px;
          overflow-y: scroll;
        }
        .preview {
          @include size(100%, 250px);
        }
      }
    }
  }
  .training {
    @include size(100%, 100%);
    background-color: transparent;
    display: block;
    position: relative;
    .selection {
      @include size(100%, 100%);
      @include position_absolute(0, 0, 0, 0);
      background-color: transparent;
      border: none;
      display: block;
      .steps {
        @include size(48%, 50%);
        @include position_absolute(0, 0, auto, auto);
        @include flexbox(column, center, space-around);
        background-color: #fff;
        &>.tag,
        &>.level,
        &>.testset {
          border: 2px solid $black;
        }
        .tag {
          @include size(85%, 55%);
          @include flexbox(column, center, space-around);
          .guidance {
            @include size(90%, 25%);
          }
          .dropdown_area {
            @include size(90%, 30%);
          }
        }
        .level {
          @include size(85%, 40%);
        }
        .testset {
          @include size(85%, 75%);
          @include flexbox(column, center, flex-start);
          margin-bottom: 10px;
          overflow-y: scroll;
          .guidance {
            @include size(90%, 25%);
          }
          .btn_group_testset {
            @include size(85%, 70%);
            @include flexbox(row, flex-start, flex-start);
          }
        }
        .return_step {
          @include size(85%, 12%);
          margin-top: 10px;
          .btn_return {
            @include size(auto, 100%);
            margin: 0;
            padding: 5px 20px;
            span {
              font: bold 16px $font_josefin_sans;
            }
            small {
              font: bold 14px $font_basic;
            }
          }
        }
      }
      .preview {
        @include size(100%, 48%);
        @include position_absolute(auto, 0, 0, 0);
        background-color: $white;
        border: 2px solid $black;
        border-radius: 10px;
      }
    }
  }
}
