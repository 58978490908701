.question {
  @include size(100%, 100%);
  background-color: $white_alpha;
  position: relative;
  .bookmark_wrapper {
    @include size(170px, 35px);
    margin: 5px auto;
    overflow: visible;
    .bookmark {
      @include size(100%, 100%);
      position: relative;
      .qnum {
        @include size(85%, 100%);
        @include flexbox(column, center, center);
        @include position_absolute(auto, 0, auto, auto);
        h2 {
          font: bold 16px $font_basic;
          margin: 0;
          .total_count{
            font-size: .7em;
          }
        }
      }
      .bookmark_r {
        @include size(91%, auto);
        @include position_absolute(auto, auto, auto, 9%);
        overflow: visible;
        path {
          fill: $white;
          stroke: $black;
          stroke-width: 4px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
        }
      }
      .bookmark_l {
        @include size(16%, auto);
        @include position_absolute(25%, auto, auto, auto);
        overflow: visible;
        path {
          fill: $black;
          stroke: $black;
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
        }
      }
    }
  }
  .question_inner {
    @include size(95%, calc(100% - 45px));
    background-color: $white;
    margin: auto;
    position: relative;
    .question_inner_inner {
      @include size(100%, calc(100% - 155px));
      overflow-y: scroll;
      padding: 5px 0;
      .qlist_mini {
        width: 175px;
        min-height: 50px;
        .qlist {
          border: 2px solid $black;
          padding: 3px;
          .spacer{
            @include size(16px, 16px);
          }
          .btn_qlist_wrapper {
            @include size(16px, 16px);
            .btn_qlist {
              @include size(14px, 8px);
              span {
                font-size: 10px;
              }
            }
          }
        }
      }
      .question_set {
        padding: 10px;
        .choices {
          @include flexbox(row, stretch, space-between);
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          width: 100%;
          .btn_choice,
          .btn_choice:hover,
          .btn_choice:not(:first-child),
          .btn_choice:not(:last-child),
          .btn_choice:not(:last-child):not(.dropdown-toggle) {
            border-radius: 10px;
            font-size: 1em;
            flex: 0 0 auto;
            margin-bottom: 10px;
            padding: 15px 10px;
            text-align: left;
            width: 49%;
          }
          .btn_choice,
          .btn_choice:hover,
          .btn_choice:not(:first-child),
          .btn_choice:not(:last-child),
          .btn_choice:not(:last-child):not(.dropdown-toggle) {
            color: $black;
            border: $black 2px solid;
            background-color: $white;
            box-shadow: none;
          }
          .btn_choice:focus,
          .btn_choice.focus,
          .btn_choice:not(:disabled):not(.disabled):active,
          .btn_choice:not(:disabled):not(.disabled).active,
          .btn_choice:not(:disabled):not(.disabled):active:focus,
          .btn_choice:not(:disabled):not(.disabled).active:focus {
            color: $black;
            border: $black 2px solid;
            background-color: $light_pink;
            box-shadow: none;
          }
        }
      }
    }
    .button_group {
      @include size(75%, 150px);
      @include position_absolute(auto, 0, 5px, 0);
      @include flexbox(row, center, space-between);
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      .btn_move {
        @include size(60px, 60px);
        border-radius: 50%;
        font-weight: bold;
        font-size: 12px;
        margin: 0 15px;
        position: relative;
        span {
          @include position_absolute(15%, 0, auto, 0);
          font-size: 11px;
        }
        .move_direction {
          @include size(35%, 35%);
          @include position_absolute(45%, 0, auto, 0);
          display: block;
        }
        .rot {
          @include transform(rotate(180deg));
        }
      }
      .btn_move,
      .btn_move:focus,
      .btn_move.focus,
      .btn_move:not(:disabled):not(.disabled):active,
      .btn_move:not(:disabled):not(.disabled).active,
      .btn_move:not(:disabled):not(.disabled):active:focus,
      .btn_move:not(:disabled):not(.disabled).active:focus {
        background-color: $btn_yellow;
        border: $black 2px solid;
        box-shadow: none;
        color: $black;
        &:hover {
          opacity: .7;
        }
        &.disabled,
        &:disabled,
        &.disabled:hover,
        &:disabled:hover {
          opacity: .65;
          cursor: unset;
        }
      }
      .button_group_confidence {
        @include size(100%, 45%);
        @include flexbox(row, center, space-between);
        order: -1;
        .btn_confidence {
          @include size(90px, 70px);
          border: solid $black 2px;
          border-radius: 10px;
          box-sizing: border-box;
          color: $black;
          font-weight: bold;
          position: relative;
          span {
            @include position_absolute(5%, 0, auto, 0);
            display: block;
            font-size: 11px;
          }
          .icon {
            @include size(45px, 45px);
            @include position_absolute(30%, 0, auto, 0);
            display: block;
            font: bold 28px $font_josefin_sans;
            .circle_outer,
            .circle_inner {
              @include position_absolute(0, 0, 0, 0);
              border-radius: 50%;
              border: 6px solid $black;
            }
            .circle_outer {
              @include size(90%, 90%);
              line-height: 1.3em;
            }
            .circle_inner {
              @include size(50%, 50%);
            }
          }
          &:disabled,
          &.disabled {
            border: solid $black 2px;
            cursor: unset;
            &:hover {
              opacity: .65;
            }
          }
          &:hover {
            opacity: .7;
          }
        }
        .conf_true {
          background-color: $btn_orange;
          &:hover {
            background-color: $btn_orange;
          }
        }
        .conf_false {
          background-color: $btn_blue;
          &:hover {
            background-color: $btn_blue;
          }
        }
        .conf_active {
          background-color: $btn_orange;
          &:hover {
            background-color: $btn_orange;
          }
        }
        .conf_active,
        .conf_active:hover,
        .conf_active:disabled,
        .conf_active.disabled,
        .conf_active:not(:disabled):not(.disabled):active,
        .conf_active:not(:disabled):not(.disabled).active,
        .conf_active:not(:disabled):not(.disabled):active:focus,
        .conf_active:not(:disabled):not(.disabled).active:focus {
          background-color: $white;
          border-color: $btn_gold;
          box-shadow: none;
          color: $btn_gold;
          .icon {
            .circle_outer,
            .circle_inner {
              background-color: $white;
              border-color: $btn_gold;
              border: 6px solid $btn_gold;
              color: $btn_gold;
            }
          }
        }
      }
    }
    .timer_wrapper {
      @include flexbox(column, center, center);
      background-color: $white;
      border: solid $black 2px;
      box-sizing: border-box;
      * {
        font-weight: bold;
        text-align: center;
      }
      h3 {
        @include size(90%, 30%);
        border-bottom: 1px solid $black;
        font-size: 9px;
        line-height: 1.8em;
        margin: 0;
      }
      .remain {
        @include size(100%, 55%);
        @include flexbox(column, center, center);
        display: block;
        font-size: 16px;
        .time_warn {
          color: #ff0000;
          font-size: 1.1em;
        }
      }
    }
    .timer_total {
      @include size(90px, 50px);
      @include position_absolute(5px, 0, auto, auto);
    }
  }
}

.qlist {
  background-color: $light_yellow;
  margin: auto;
  padding: 3px 0;
  @include flexbox(row, center, space-between);
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  .spacer{
    @include size(50px, 50px);
  }
  .btn_qlist_wrapper {
    @include size(50px, 50px);
    @include flexbox(row, center, center);
    .btn_qlist {
      @include size(42px, 24px);
      border: none;
      border-radius: 0;
      display: block;
      padding: 0;
      position: relative;
      transition: all 0.2s linear;
      span {
        color: $black;
        display: block;
        font-size: 28px;
        font-weight: bold;
        margin-top: -0.3em;
        position: relative;
        text-align: center;
        z-index: 1;
      }
      &::before,
      &::after {
        @include size(100%, 100%);
        @include position_absolute(0, auto, auto, 0);
        content: '';
        transition: all 0.2s linear;
        z-index: 0;
      }
      &::before {
        @include transform(rotate(60deg));
      }
      &::after {
        @include transform(rotate(-60deg));
      }
      &.disabled,
      &:disabled {
        cursor: default;
        opacity: 1;
      }
    }
    .conf_undef,
    .conf_undef.disabled,
    .conf_undef:disabled,
    .conf_undef.disabled:hover,
    .conf_undef:disabled:hover {
      background-color: #fff;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
    .conf_undef:hover,
    .conf_undef:focus,
    .conf_undef.focus,
    .conf_undef:not(:disabled):not(.disabled).active,
    .conf_undef:not(:disabled):not(.disabled):active,
    .conf_undef:not(:disabled):not(.disabled).active:focus,
    .conf_undef:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      background-color: $light_gray;
      &::before,
      &::after {
        box-shadow: none;
        background-color: $light_gray;
      }
    }
    .conf_true,
    .conf_true:hover,
    .conf_true:focus,
    .conf_true.focus,
    .conf_true.disabled,
    .conf_true:disabled,
    .conf_true:not(:disabled):not(.disabled).active,
    .conf_true:not(:disabled):not(.disabled):active,
    .conf_true:not(:disabled):not(.disabled).active:focus,
    .conf_true:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      background-color: $btn_orange;
      &::before,
      &::after {
        box-shadow: none;
        background-color: $btn_orange;
      }
    }
    .conf_false,
    .conf_false:hover,
    .conf_false:focus,
    .conf_false.focus,
    .conf_false.disabled,
    .conf_false:disabled,
    .conf_false:not(:disabled):not(.disabled).active,
    .conf_false:not(:disabled):not(.disabled):active,
    .conf_false:not(:disabled):not(.disabled).active:focus,
    .conf_false:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      background-color: $btn_blue;
      &::before,
      &::after {
        box-shadow: none;
        background-color: $btn_blue;
      }
    }
    .btn_qlist:disabled,
    .btn_qlist.disabled {
      cursor: default;
    }
  }
}
.qlist_modal {
  width: 260px;
  margin: auto;
  .modal-content {
    .qlist {
      padding: 5px;
      width: 260px;
    }
  }
}

@media screen and (min-width: $break_point) {
  .question {
    background-color: transparent;
    .bookmark_wrapper {
      @include size(340px, 75px);
      @include position_absolute(30px, 0, auto, 0);
      z-index: 100;
      .bookmark {
        .qnum {
          h2 {
            font-size: 28px;
          }
        }
      }
    }
    .meta {
      @include size(200px, 80px);
      @include position_absolute(15px, 15px, auto, auto);
      z-index: 999;
      .tag,
      .level {
        span {
          font-size: 16px;
        }
      }
    }
    .question_inner {
      @include size(100%, 100%);
      .question_inner_inner {
        @include size(100%, calc(100% - 120px));
        .qlist_mini {
          width: 240px;
          min-height: 100px;
          margin: 10px 0 0 15px;
          .qlist {
            padding: 10px;
            .spacer{
              @include size(20px, 20px);
            }
            .btn_qlist_wrapper {
              @include size(20px, 20px);
              .btn_qlist {
                @include size(17px, 10px);
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .question_set {
          width: 90%;
          margin: 20px auto 0;
          padding: 0;
          font-size: 16px;
        }
      }
      .button_group {
        @include size(480px, 90px);
        @include position_absolute(auto, 0, 20px, 0);
        .btn_move {
          @include size(80px, 80px);
          margin: auto;
          span {
            font-size: 13px;
          }
        }
        .button_group_confidence {
          @include size(58%, 100%);
          order: 0;
          .btn_confidence {
            @include size(45%, 100%);
            border-radius: 22px;
            span {
              font-size: 13px;
            }
            .icon {
              @include size(50px, 50px);
            }
          }
        }
      }
      .timer_wrapper {
        border: solid $black 3px;
        h3 {
          border-bottom: 2px solid $black;
          font-size: 15px;
        }
        .remain {
          font-size: 24px;
        }
      }
      .timer_total {
        @include size(180px, 90px);
        @include position_absolute(auto, 15px, 15px, auto);
      }
    }
  }
  .qlist_modal {
    width: 560px;
    .modal-content {
      .qlist {
        width: 560px;
        padding: 15px 30px;
      }
    }
  }
}
