html,
body {
  @include size(100%, 100%);
  margin: 0;
  padding: 0;
}

body {
  background: $bg center/cover no-repeat fixed;
  color: $black;
  font: 16px $font_basic;
}

.wrapper {
  @include size(100%, 100%);
  @include position_absolute(0,0,0,0);
  @include flexbox(column, center, center);
}

main {
  @include size(90%, 80%);
  margin-top: 3%;
}

.login_msg {
  color: $dark_gray;
  font: 20px $font_basic;
  margin: 10px;
}

.spinner{
  @include flexbox(row, center, flex-start);
  margin: 15px;
  .spinner-grow {
    @include size(2em, 2em);
    margin-right: .8em;
    margin-bottom: .3em;
  }
  .loading {
    color: $dark_gray;
    font: 24px $font_josefin_sans;
  }
}

header {
  @include size(90%, 15%);
  @include flexbox(row, flex-start, space-between);
  .logo {
    @include size(50%, 100%);
    background: $logo center/contain no-repeat;
  }
}

.question_set {
  font: bold 14px $font_basic;
  overflow-y: scroll;
  * {
    font-weight: bold;
  }
  .instruction,
  .question_ {
    display: block;
    margin-bottom: .9em;
  }
  .choices {
    div {
      display: inline;
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
  .sentence {
    display: block;
  }
}

.q_format {
  display: inline;
  .brackets {
    display: inline-block;
    width: 3em;
  }
  .num {
    color: $light_gray;
    font-size: .8em;
    margin: 0 .1em;
  }
  .underline {
    border-bottom: 2px solid $light_gray;
  }
  .fillin {
    border: 2px solid $light_gray;
    // border-bottom: 2px solid $light_gray;
    display: inline-block;
    margin: 0 .1em;
    width: .8em;
    height: .8em;
  }
}

.training,
.result {
  .user {
    @include size(90%, 28%);
    @include flexbox(column, center, space-between);
    font-size: 13px;
    .personal {
      @include size(100%, 55%);
      @include flexbox(column, center, center);
      background-color: $white;
      border: 2px solid $black;
      .name,
      .school {
        @include flexbox(column, center, center);
      }
      .school {
        .teacher {
          font-size: .8em;
        }
      }
    }
    .target {
      @include size(100%, 40%);
      @include flexbox(column, center, center);
      background-color: $light_yellow;
      border: 2px solid $black;
    }
    span,
    small {
      font-weight: bold;
    }
  }
}

.question,
.result {
  .meta {
    @include size(130px, 70px);
    @include position_absolute(-80px, 0, auto, auto);
    @include flexbox(column, center, space-between);
    .tag {
      @include size(100%, 55%);
      @include flexbox(column, center, center);
      padding: 5px;
      span {
        font: bold 12px $font_basic;
        line-height: 1.2;
      }
    }
    .level {
      @include size(100%, 42%);
      @include flexbox(column, center, center);
      border: $blue 4px solid;
      background-color: $white;
      color: $blue;
      span {
        font: bold 14px $font_basic;
      }
    }
  }
}

@each $tag in $tags {
  .tag_#{$tag},
  .tag_#{$tag}:hover,
  .tag_#{$tag}:focus,
  .tag_#{$tag}.focus,
  .tag_#{$tag}:not(:disabled):not(.disabled):active,
  .tag_#{$tag}:not(:disabled):not(.disabled).active,
  .tag_#{$tag}:not(:disabled):not(.disabled):active:focus,
  .tag_#{$tag}:not(:disabled):not(.disabled).active:focus {
    background-color: map-get($tag_bg, #{$tag});
    color: map-get($tag_font_color, #{$tag});
  }
}

@media screen and (min-width: $break_point_s) {
  .training,
  .result {
    .user {
      font-size: 16px;
      .personal {
        .name {
          margin-bottom: 5px;
        }
      }
    }
  }
  .question,
  .result {
    .meta {
      @include size(200px, 80px);
      @include position_absolute(-90px, 0, auto, auto);
      .tag {
        span {
          font: bold 14px $font_basic;
        }
      }
    }
  }
}

@media screen and (min-width: $break_point) {
  .wrapper {
    @include size($max_width, $max_height);
    background-color: $white_alpha;
  }

  header {
    @include size(90%, 10%);
    .logo {
      @include size(24%, 100%);
    }
  }

  .training,
  .result {
    .user {
      @include size(48%, 50%);
      @include flexbox(column, center, space-around);
      font-size: 16px;
      .personal {
        @include size(100%, 48%);
        @include flexbox(column, flex-start, space-around);
        background-color: transparent;
        border: none;
        .name,
        .school {
          @include size(100%, 40%);
          background-color: $white;
          border: 2px solid $black;
        }
      }
      .target {
        @include size(100%, 40%);
      }
    }
  }

  .question,
  .result {
    .meta {
      @include size(200px, 80px);
      @include position_absolute(-90px, 0, auto, auto);
      .tag {
        span {
          font: bold 14px $font_basic;
        }
      }
    }
  }
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: 0px;
}