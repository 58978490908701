$break_point:   896px;
$break_point_s: 480px;

$max_width: 1000px;
$max_height: 750px;

// STATIC FILE PATH
$logo:   url(/static/training_logo.png);
$bg:     url(/static/ID200201_tfa_ta_bg.png);

// COLOR
$black:            #000;
$white:            #fff;
$white_alpha:      rgba(255,255,255,0.3);
$dark_gray:        #666;
$light_gray:       #aaa;

$red:              #eb3b2f;
$red_alpha:        rgba(235,59,47,0.7);
$pink:             #ef857d;
$pink_alpha:       rgba(239,133,125,0.7);
$light_pink:       #e9c9ce;
$light_pink_alpha: rgba(233,201,206,0.7);

$blue:             #00a1e9;
$dull_blue:        #619fc9;
$dull_blue_alpha:  rgba(97,159,201,0.7);

$light_yellow:     #efebaf;

$btn_yellow:       #f0eaad;
$btn_orange:       #c75b25;
$btn_blue:         #6eb5e9;
$btn_gold:         #b8860b;
$btn_pink:         $light_pink;
$btn_pink_alpha:   rgba(233,201,206,0.7);

// FONT
$font_basic: '游ゴシック Medium','Yu Gothic Medium',YuGothic,YuGothicM,'Hiragino Kaku Gothic ProN','Hiragino Kaku Gothic Pro',メイリオ,Meiryo,sans-serif;
$font_noto_sans: 'Noto Sans JP';
$font_josefin_sans: 'Josefin Sans', sans-serif;

// TAGS
$tags: A,B,C,D,E,F,G,H,I,J,K,L,V-0,V-1,V-2,V-3,V-4,V-5,X-0,X-1,X-2,X-3,X-4,X-5;
$tag_bg: (
  A: #ff7f7f,
  B: #ff7fbf,
  C: #ff7fff,
  D: #bf7fff,
  E: #7f7fff,
  F: #7fbfff,
  G: #7fffff,
  H: #7fffbf,
  I: #7fff7f,
  J: #bfff7f,
  K: #ffff7f,
  L: #ffbf7f,
  V-0: #3f3f3f,
  V-1: #5f5f5f,
  V-2: #7f7f7f,
  V-3: #9f9f9f,
  V-4: #bfbfbf,
  V-5: #dfdfdf,
  X-0: #958173,
  X-1: #ab9b90,
  X-2: #c1b5ad,
  X-3: #d7cfca,
  X-4: #edeae7,
  X-5: #edeae7,
);
$tag_font_color: (
  A: #2f2f2f,
  B: #2f2f2f,
  C: #2f2f2f,
  D: #2f2f2f,
  E: #2f2f2f,
  F: #2f2f2f,
  G: #2f2f2f,
  H: #2f2f2f,
  I: #2f2f2f,
  J: #2f2f2f,
  K: #2f2f2f,
  L: #2f2f2f,
  V-0: #fff,
  V-1: #fff,
  V-2: #fff,
  V-3: #fff,
  V-4: #fff,
  V-5: #fff,
  X-0: #2f2f2f,
  X-1: #2f2f2f,
  X-2: #2f2f2f,
  X-3: #2f2f2f,
  X-4: #2f2f2f,
  X-5: #2f2f2f,
);